import _ from 'underscore';
import classNames from 'classnames';
import React, { FC, useState, useContext } from 'react';

import { Dropdown, Loader } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { textEllipsis } from '@/common';
import NewRevealJobModal from '@/components/NewRevealJobModal';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import useQueryParams from '@/hooks/router/useQueryParams';
import useCreateSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useCreateSearchPoolJob';
import { ADVENTURE, ATStype } from '@/common/reveal';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useMiniMission from '@/graphql/hooks/searchPoolJob/useMiniMission';

import useMiniMissions from '@/graphql/hooks/searchPoolJobs/useMiniMissions';
import useMiniMissionsWithMetadata from '@/graphql/hooks/searchPoolJobs/useMiniMissionsWithMetadata';
import { MiniMissionWithMetadata } from '@/types/mission';

import styles from '../../components/JobBreadCrumb.module.less';
import './JobSelector.css';


const MAX_N_CHARACTERS = 30;

type JobOption = {
  id: string;
  data: {
    title: string;
  };
  metadata?: { key: string; value?: string }[];
};

interface GenericDropdownProps {
  clientId: string;
  loading?: boolean;
  jobId: string;
  jobOptions?: JobOption[];
  targetPage: string;
  searchPoolId: string;
  shouldHideNewProjectButton?: boolean;
  onClick: () => void;
}

function getDropdownItemJobTitle({
  job,
  atsId,
}: {
  job: JobOption;
  atsId: ATStype;
}) {
  const defaultTitle = textEllipsis(job.data?.title || '', MAX_N_CHARACTERS);
  if ([ADVENTURE].includes(atsId)) {
    const agencyCode =
      _.findWhere(job.metadata || [], { key: 'agencyCode' })?.value ?? null;
    if (!agencyCode) {
      return defaultTitle;
    }
    return `${agencyCode}-${defaultTitle}`;
  }

  return defaultTitle;
}

const GenericDropdown: FC<GenericDropdownProps> = ({
  clientId,
  loading,
  onClick,
  jobId,
  jobOptions = [],
  targetPage,
  searchPoolId,
  shouldHideNewProjectButton,
}) => {
  const { t } = useTranslation();
  const atsId = useClientCurrentAtsId();
  const history = useHistory();
  const queryParams = useQueryParams<{
    segmentId?: string;
    segmentationId?: string;
  }>();
  const [newJobModalOpen, setNewJobModalOpen] = useState(false);
  const { sortProjectsAlphabeticallyInBreadcrumbs } =
    useMergedConfigurationParams();
  const missionCategory = useContext(MissionCategoryContext);

  const [
    createNewJobMutation,
    { loading: creatingNewJob, data: createJobData },
  ] = useCreateSearchPoolJob();

  const sortedJobs =
    sortProjectsAlphabeticallyInBreadcrumbs === 'true'
      ? _.sortBy(jobOptions, (job) =>
        getDropdownItemJobTitle({ job, atsId: atsId as any }),
      )
      : jobOptions;

  const onClickAllJobs = () => {
    history.push(`/client/${clientId}/reveal/projects/${missionCategory}`);
  };

  const onClickItem = (targetJob: JobOption) => {
    history.push(
      `/client/${clientId}/reveal/projects/${missionCategory}/${targetJob.id
      }/${targetPage}${targetPage === 'profiles' &&
        queryParams?.segmentationId &&
        queryParams?.segmentId
        ? `?segmentationId=${queryParams.segmentationId}&segmentId=${queryParams?.segmentId}`
        : ''
      }`,
    );
  };

  return (
    <>
      <div className={classNames('generic-dropdown', styles.breadCrumbSection)}>
        <Dropdown
          className='hs-dropdown'
          icon={<i className='ri-arrow-drop-down-fill' />}
          onClick={(e) => { onClick?.(); e.preventDefault() }}
        >
          {loading ? (
            <Dropdown.Menu className='job-selector-menu'>
              <Dropdown.Item
                key='loader'
                className='large-item'
              >
                <center style={{ width: 200 }}>
                  <Loader active inline />
                </center>
              </Dropdown.Item>
            </Dropdown.Menu>
          ) : (
            <Dropdown.Menu className='job-selector-menu'>

              {_.map(sortedJobs, (targetJob, index) => (
                <Dropdown.Item
                  key={index}
                  className={classNames(
                    'large-item',
                    jobId && targetJob.id === jobId && 'current',
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    onClickItem(targetJob);
                  }}
                >
                  <span>
                    {getDropdownItemJobTitle({ job: targetJob, atsId: atsId as any })}
                  </span>
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                className='large-item'
                onClick={(e) => {
                  e.preventDefault();
                  onClickAllJobs();
                }}
              >
                {t('missions.header.allMissions')}
              </Dropdown.Item>
              {!shouldHideNewProjectButton && (
                <Dropdown.Item className='large-item item-new-job'>
                  <div className='new-job-button-container'>
                    <GenericButton
                      onClick={(e) => {
                        e.preventDefault();
                        setNewJobModalOpen(true);
                      }}
                    >
                      <Plus />
                      {t('missions.header.newMission')}
                    </GenericButton>
                  </div>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div >
      <NewRevealJobModal
        open={newJobModalOpen}
        onClose={() => setNewJobModalOpen(false)}
        searchPoolId={searchPoolId}
        clientId={clientId}
        onSubmit={createNewJobMutation}
        loading={creatingNewJob}
        queryResponse={createJobData}
      />
    </>
  );
};

interface JobSelectorProps {
  clientId: string;
  jobId?: string;
  targetPage: string;
  searchPoolId: string;
  shouldHideNewProjectButton?: boolean;
}


const JobSelector: FC<JobSelectorProps> = ({
  clientId,
  jobId,
  targetPage,
  searchPoolId,
  shouldHideNewProjectButton,
}) => {
  const missionCategoryType = useContext(MissionCategoryContext);
  const [isEnabled, setIsEnabled] = useState(false);

  const shouldUseMetadata = clientId.includes('adequat');

  const { loading: loadingWithoutMetadata, miniMissions: miniMissionsWithoutMetadata } = useMiniMissions({
    filters: {
      missionCategory: { type: missionCategoryType },
      activeOnly: true,
    },
    queryOptions: {
      skip: shouldUseMetadata || !isEnabled,
    }
  });

  const { loading: loadingWithMetadata, miniMissionsWithMetadata } = useMiniMissionsWithMetadata({
    filters: {
      missionCategory: { type: missionCategoryType },
      activeOnly: true,
    },
    queryOptions: {
      skip: !shouldUseMetadata || !isEnabled
    }
  });

  const loading = loadingWithoutMetadata || loadingWithMetadata;

  const miniMissions = (
    shouldUseMetadata ? miniMissionsWithMetadata : miniMissionsWithoutMetadata
  ) as MiniMissionWithMetadata[];

  const { miniMission: currentJob } = useMiniMission(jobId ?? '', {
    queryOptions: {
      skip: !jobId,
    },
  });

  const queryParams = useQueryParams<{
    segmentId?: string;
    segmentationId?: string;
  }>();

  if (!jobId) {
    return null;
  }

  const currentJobTitle = currentJob?.data?.title || '';

  return (
    <div
      style={{ display: 'flex', minWidth: 0 }}
      onMouseEnter={() => setIsEnabled(true)}
    >
      <Link
        to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}${queryParams?.segmentationId && queryParams?.segmentId
          ? `/profiles?segmentationId=${queryParams.segmentationId}&segmentId=${queryParams?.segmentId}`
          : ''
          }`}
        className={classNames(styles.breadCrumbSection, styles.collapsible)}
      >
        {currentJobTitle}
      </Link>
      <GenericDropdown
        onClick={() => {
          // technically already triggered when mouse enters
          // but safer if mouse already in component or
          // if event was not trigger
          setIsEnabled(true)
        }}
        clientId={clientId}
        jobId={jobId}
        jobOptions={miniMissions ?? []}
        loading={loading}
        targetPage={targetPage}
        searchPoolId={searchPoolId}
        shouldHideNewProjectButton={shouldHideNewProjectButton}
      />
    </div>
  );
};

export default JobSelector;
